<template>
  <div class="helper w100 autobox2" v-loading="loading" :element-loading-text="config.loading.text"
        :element-loading-spinner="config.loading.spinner" :element-loading-background="config.loading.background">
    <div class="fcb">
      <div class="fs-20 fw-b">操作助手</div>
      <div class="c-999 fs-14 fcb cu-p" @click="router_push_name('topHelper',undefined,undefined,true)">
        查看更多
        <el-image :src="require('@/assets/img/home/right.png')" />
      </div>
    </div>
    <div class="mt-20 fs-14">
      <div class="cu-p row mb-18"  v-for="(item,index) in state.list" :key="index" @click="router_push_name('topHelper',{id:item.id},undefined,true)">
        <div>{{item.title}}</div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref,reactive} from "vue"
import { httpToken } from "@/utils/request";
import {router_push_name} from '@/utils/server/router'
import qs from "qs";
const loading = ref(false);//loading 显示
let state = reactive({
  list:[]
})

// 获取操作助手数据
const getData = () => {
  loading.value = true
  httpToken({
    method: "post",
    url: 'http://api.manage.yilanshuzi.com/backstage/contentmanagement/selectOperateList',
    // url: 'http://116.63.252.12:9999/backstage/contentmanagement/selectOperateList',
    data:qs.stringify({
      rows:3
    })
  }).then(res => {
    state.list=res.data
    loading.value = false;
  }).catch((err) => {
    loading.value = false;
  })
}
getData()
</script>
<style lang="scss" scoped>
.helper {
  padding: 30px 30px 23px;
  background-color: #fff;
  height: 230px;
  margin-top: 12px;
  .fcb {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .row {
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    border-bottom: 1px #EDEDED solid;
    position: relative;
  }
}
</style>